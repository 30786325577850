@import '../font-face.scss';

a {
  text-decoration: none;
  color: inherit;
}

p,
span,
b,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;

  &.text-center {
    text-align: center;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'LibreCaslonText';
}

p, span, b, strong {
  font-family: 'Fira Sans';
}
