@import "./app/styles/design/breakpoints.scss";
@import "./app/styles/design/typography.scss";

* {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

  /* &::-webkit-scrollbar { // Styles to change scrollbar in case of need
            width: .5rem;
        }
    
        &::-webkit-scrollbar-track {
          background-color: #4d9068;
        }
      
        &::-webkit-scrollbar-thumb {
            background-color: $main-green;
            border: 1px solid $vi-turq;
            border-radius: 30px;
        } */
}

html {
  overflow-x: hidden;
}
